import React from "react"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import ScrollNavigationItem from "./scroll-navigation-item"
import Sticky from "react-stickynode"

import "./scroll-navigation.css"
const ScrollNavigation = ({ scrollNavigationItems }) => {
  if (typeof window !== `undefined`) {
    const { height, width } = useWindowDimensions()
  }

  return (
    <div className="sticky-container">
      <Sticky enabled={true} top={0} innerZ={34}>
        <div className="scroll-nav-wrap">
          <div className="scroll-down-wrap" styles="z-index:34;">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
            <span>Runter scrollen</span>
          </div>

          <nav className="scroll-nav scroll-init">
            <ul>
              {scrollNavigationItems.map((item, idx) => (
                <ScrollNavigationItem item={item} key={idx} />
              ))}
            </ul>
          </nav>
        </div>
      </Sticky>
    </div>
  )
}

export default ScrollNavigation
