import React, { Component } from "react"
import "./story.css"
import { Link, StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { Parallax } from "react-scroll-parallax"
import SecLines from "../sec-lines/sec-lines"
import Img from "gatsby-image"

const query = graphql`
  {
    cv: file(sourceInstanceName: { eq: "files" }, name: { eq: "cv-jezekjan" }) {
      id
      name
      publicURL
    }
    resumes: allFile(
      filter: { sourceInstanceName: { eq: "resume" }, extension: { eq: "md" } }
      sort: { order: ASC, fields: childMarkdownRemark___frontmatter___yearFrom }
    ) {
      totalCount
      nodes {
        childMarkdownRemark {
          rawMarkdownBody
          id
          html
          frontmatter {
            title
            yearFrom
            jobTitle
            yearTo
            buttonText
            url
            image {
              childImageSharp {
                fluid(maxWidth: 250, maxHeight: 160, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

class Story extends Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={({ resumes, cv }) => {
          return (
            <section data-scrollax-parent="true" id="sec3">
              <Parallax
                y={[-50, 50]}
                className="section-subtitle"
                styleInner={{ color: "black", fontSize: "125px" }}
              >
                Lebenslauf
              </Parallax>
              <Container>
                <div className="section-title fl-wrap">
                  <h3>Einige Worte über mich</h3>
                  <h2>
                    Meine <span> Geschichte</span>
                  </h2>
                  <p>
                    Ich habe ein breites Kompetenzspektrum und viele Interessen.
                    Im Speziellen interessiert mich Technik, aber auch
                    Philosophie, Weltgeschehen sowie kreative Lösungen zur
                    "Heilung" der Welt und Gesellschaft. Dabei ist "um's Eck
                    denken" und "Punkte verbinden" meine Leidenschaft.
                  </p>
                </div>
                <div className="custom-inner-holder">
                  {resumes.nodes.map(({ childMarkdownRemark }, idx) => {
                    return (
                      <div className="custom-inner" key={idx}>
                        <Row>
                          <Col md={4}>
                            <div className="custom-inner-header workres">
                              <i className="fa fa-briefcase"></i>
                              <h3>
                                {childMarkdownRemark.frontmatter.jobTitle}
                              </h3>
                              <span>
                                {childMarkdownRemark.frontmatter.yearFrom} -
                                {childMarkdownRemark.frontmatter.yearTo}
                              </span>
                            </div>
                            <div className="ci-num">
                              <span>{idx + 1}. -</span>
                            </div>
                          </Col>

                          {childMarkdownRemark.frontmatter.image ? (
                            <Col md={4}>
                              <Parallax
                                y={[-10, 10]}
                                styleInner={{
                                  color: "black",
                                  fontSize: "125px",
                                }}
                              >
                                <Img
                                  fluid={
                                    childMarkdownRemark.frontmatter.image
                                      .childImageSharp.fluid
                                  }
                                  className="respimg"
                                  alt={childMarkdownRemark.frontmatter.jobTitle}
                                />
                              </Parallax>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col
                            md={childMarkdownRemark.frontmatter.image ? 4 : 8}
                          >
                            <div className="custom-inner-content fl-wrap">
                              <h4
                                dangerouslySetInnerHTML={{
                                  __html: childMarkdownRemark.frontmatter.title,
                                }}
                              ></h4>

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: childMarkdownRemark.html,
                                }}
                              ></div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )
                  })}
                </div>
                <a
                  href={cv.publicURL}
                  target="_blank"
                  className="btn float-btn flat-btn color-btn mar-top"
                >
                  Lebenslauf Herunterladen
                </a>
              </Container>
              <SecLines />
            </section>
          )
        }}
      ></StaticQuery>
    )
  }
}

export default Story
