import React from "react"
import Footer from "../components/footer/footer"
import ScrollNavigation from "../components/scroll-navigation/scroll-navigation"
import Hero from "../components/hero/hero"
import VideoIntroduction from "../components/video-introduction/video-introduction"
import Facts from "../components/facts/facts"
import Story from "../components/story/story"
import WorkProcess from "../components/work-process/work-process"
import Skills from "../components/skills/skills"
import FeaturedProjects from "../components/featured-projects/featured-projects"
import Testimonials from "../components/testimonials/testimonials"
import GetInTouch from "../components/get-in-touch/get-in-touch"
import Introduction from "../components/introduction/introduction"
import ShareContent from "../components/share-content/share-content"
import "./home.css"
import useWindowDimensions from "../hooks/useWindowDimensions"
import GetInTouchCta from "../components/get-in-touch-cta/get-in-touch-cta"
import SEO from "../components/seo"

const IndexPage = () => {
  if (typeof window !== `undefined`) {
    const { height, width } = useWindowDimensions()
  }
  const scrollNavigationItems = [
    { title: "Start", url: "#sec1", classes: ["act-link"], subnav: [] },
    { title: "Über mich", url: "#sec2", classes: [""], subnav: [] },
    { title: "Lebenslauf", url: "#sec3", classes: [""], subnav: [] },
    { title: "Projekte", url: "#sec5", classes: [""], subnav: [] },
    { title: "Kunden", url: "#sec6", classes: [""], subnav: [] },
  ]
  return (
    <>
      <SEO
        title="Web-, App-Entwickler, Musik und Multimedia Designer und Content Creator"
        description="Ich entwickle Ihre Website, App oder verbessere Ihre bestehenden Systeme. Egal ob sie die Geschwindigkeit erhöhen, um Funktionen erweitern oder komplett neue Innovationen wollen. Fragen Sie mich!"
      />
      <div id="wrapper">
        <Hero />
        <ScrollNavigation scrollNavigationItems={scrollNavigationItems} />
        <div className="content">
          <Introduction />
          <Facts />
          <WorkProcess />
          <Skills />
          <VideoIntroduction />
          <Story />
          <FeaturedProjects />
          <Testimonials />
          <GetInTouch />
        </div>
        <div className="height-emulator fl-wrap"></div>
        <Footer />
        <GetInTouchCta />
      </div>
      <ShareContent />
    </>
  )
}

export default IndexPage
