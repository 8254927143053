import React from "react"
import PropTypes from "prop-types"
import scrollTo from "gatsby-plugin-smoothscroll"

const ScrollNavigationItem = ({ item }) => {
  return (
    <li key={item.index}>
      <a className={item.classes.join("-")} onClick={() => scrollTo(item.url)}>
        {item.title}
      </a>
    </li>
  )
}
ScrollNavigationItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    classes: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
  }),
}
export default ScrollNavigationItem
