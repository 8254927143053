import React from "react"
import { Link } from "gatsby"

import "./video-introduction.css"
import Dots from "../animated-background/animated-background-dot"
import SecLines from "../sec-lines/sec-lines"
import ReactPlayer from "react-player"
const VideoIntroduction = () => {
  const content = {
    title: "DJ & Visuals",
    content:
      "Meine Leidenschaft ist Musik Produktion, DJ und Videoprojektionen",
    video_url: "https://vimeo.com/66245257",
    video_link: "/visuals-vj-vitamin-d",
    audio_link: "/music",
    button_text: "Zu DJ / VJ wechseln",
  }
  return (
    <section className="dark-bg sinsec-dec sinsec-dec2">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <ReactPlayer width="100%" url="https://vimeo.com/66245257" />
          </div>
          <div className="col-md-6">
            <div className="video-promo-text fl-wrap mar-top">
              <h3>{content.title} </h3>
              <p>{content.content}</p>
              <Link
                to={content.audio_link}
                className="btn float-btn flat-btn color-btn mar-top "
              >
                Musik
              </Link>
              <Link
                to={content.video_link}
                className="btn float-btn flat-btn color-btn mar-top ml-5"
              >
                VJ
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="half-bg-dec single-half-bg-dec">
        <Dots />
      </div>

      <SecLines />
    </section>
  )
}

export default VideoIntroduction
