import React from "react"
import Img from "gatsby-image"
import "./consumer-quotes-item.css"
const ConsumerQuotesItem = ({ quote }) => {
  return (
    <div className="slick-item">
      <div className="text-carousel-item">
        <div className="popup-avatar">
          <Img
            fluid={
              quote.node.childMarkdownRemark.frontmatter.image.childImageSharp
                .fluid
            }
            className="rounded"
            alt={quote.node.childMarkdownRemark.frontmatter.name}
          />
        </div>
        <div className="listing-rating card-popup-rainingvis">
          {Array.apply(null, {
            length: quote.node.childMarkdownRemark.frontmatter.stars,
          }).map((e, idx) => (
            <i key={idx} className="fas fa-star"></i>
          ))}
        </div>
        <div className="review-owner fl-wrap">
          {quote.node.childMarkdownRemark.frontmatter.name} -
          <span>{quote.node.childMarkdownRemark.frontmatter.jobTitle}</span>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: quote.node.childMarkdownRemark.html,
          }}
        ></div>
      </div>
    </div>
  )
}

export default ConsumerQuotesItem
