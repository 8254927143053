import React from "react"
import { Button } from "reactstrap"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import scrollTo from "gatsby-plugin-smoothscroll"

const HeroText = ({ title, subline, button, url }) => {
  return (
    <div className="half-slider-item fl-wrap">
      <div className="half-hero-wrap">
        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        <h3 dangerouslySetInnerHTML={{ __html: subline }}></h3>
        <div className="clearfix"></div>
        {/*Check for Anchor or Regular links */}
        {/^#/.test(url) ? (
          <Button
            onClick={() => scrollTo(url)}
            className="btn float-btn flat-btn color-btn mar-top"
          >
            {button}
          </Button>
        ) : (
          <AniLink
            cover
            direction="down"
            duration={2}
            bg="#2c2d32"
            to={url}
            className="btn float-btn flat-btn color-btn mar-top"
          >
            {button}
          </AniLink>
        )}
      </div>
    </div>
  )
}

export default HeroText
