import React, { Component } from "react"
import GaugeChart from "react-gauge-chart"
import Gauge from "../charts/gauge-chart"
import SecLines from "../sec-lines/sec-lines"
import HorizontalBar from "../charts/horizontal-bar"
import { Container, Row } from "reactstrap"
import "./skills.css"
import DesignSkills from "./DesignSkills"
import LanguageSkills from "./LanguageSkills"
import DeveloperSkills from "./DeveloperSkills"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const query = graphql`
  {
    image: file(name: { eq: "skills" }) {
      id
      childImageSharp {
        fluid(
          maxWidth: 800
          jpegQuality: 50
          toFormat: JPG
          fit: COVER
          cropFocus: CENTER
          maxHeight: 400
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class Skills extends Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={({ image }) => {
          return (
            <>
              <section
                className="parallax-section dark-bg sec-half parallax-sec-half-left"
                data-scrollax-parent="true"
                id="sec4"
              >
                <Img
                  className="bg par-elem"
                  fluid={image.childImageSharp.fluid}
                  alt="Skills"
                  style={{ objectFit: "cover" }}
                ></Img>
                <div className="overlay"></div>
                <div className="container">
                  <div className="section-title">
                    <h2>
                      Meine Fähigkeiten
                      <br /> als <span> Developer </span>
                    </h2>
                    <p>
                      Es handelt sich hier um einen Überblick meiner
                      Kompetenzen. Sollten für Ihr Projekt noch gewisse Lücken
                      gefüllt werden, greife ich gerne auf ein Netzwerk von
                      Fachleuten zurück.
                    </p>
                    <div className="horizonral-subtitle">
                      <span>POWER</span>
                    </div>
                  </div>
                </div>
              </section>
              <section data-scrollax-parent="true">
                <div
                  className="section-subtitle right-pos"
                  data-scrollax="properties: { translateY: '-250px' }"
                >
                  <span>/</span>Abschlüsse
                </div>
                <Container>
                  <DeveloperSkills
                    title="Design Skills"
                    index=".01"
                    description="Ich bin Ihr Partner bei der Umsetzung von Flyer, Folder,
              Broschüren, Visitenkarten und ScreenDesigns"
                    types={[
                      { type: "Layout Design", value: 85 },
                      { type: "Produktfotografie", value: 72 },
                      { type: "Bildbearbeitung", value: 88 },
                    ]}
                  />
                  <DeveloperSkills
                    title="Developer Skills"
                    index=".02"
                    description="Ich setzte Ihr Projekt mit folgenden Programmiersprachen um"
                    types={[
                      { type: "Ruby", value: 91 },
                      { type: "Javascript", value: 72 },
                      { type: "Python", value: 63 },
                      { type: "C++", value: 54 },
                    ]}
                  />
                  <DeveloperSkills
                    title="Übergreifende Kompetenzen"
                    index=".03"
                    description="Sie können mich unter anderem auch für folgende Tätigkeiten engagieren"
                    types={[
                      { type: "Musikproduktion", value: 95 },
                      { type: "Videoschnitt", value: 83 },
                      { type: "Veranstaltungsmanagement", value: 92 },
                      { type: "Kamera", value: 77 },
                    ]}
                  />
                </Container>
                <SecLines />
              </section>
            </>
          )
        }}
      ></StaticQuery>
    )
  }
}

export default Skills
