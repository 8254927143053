import React, { Component } from "react"
import SliderCarousel from "../slider-carousel/slider-carousel"
import { Link, graphql, StaticQuery } from "gatsby"
import "./featured-projects.css"

const query = graphql`
  {
    projects: allFile(
      filter: {
        sourceInstanceName: { eq: "projects" }
        childMarkdownRemark: { frontmatter: { featured: { eq: true } } }
      }
    ) {
      nodes {
        id
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            featured
            name
            category
            url
            featuredImage {
              id
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

class FeaturedProjects extends Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={data => {
          return (
            <section className="dark-bg" id="sec5">
              <div className="fet_pr-carousel-title">
                <div className="fet_pr-carousel-title-item">
                  <h3>Ausgewählte Projekte</h3>
                  <p>Dies sind meine persönlich Favoriten.</p>
                  <Link
                    to="/projects"
                    className="btn float-btn flat-btn color-btn mar-top"
                  >
                    Alle Projekte anzeigen
                  </Link>
                </div>
              </div>
              <SliderCarousel projects={data.projects} />
            </section>
          )
        }}
      ></StaticQuery>
    )
  }
}

export default FeaturedProjects
