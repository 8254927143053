import React from "react"
import Link from "gatsby-plugin-transition-link/AniLink"
import { Container, Row, Col } from "reactstrap"
import { Parallax } from "react-scroll-parallax"
import "./introduction.css"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const introductionQuery = graphql`
  {
    introImage: file(
      name: { eq: "introduction" }
      sourceInstanceName: { eq: "page-content" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fluid(
          toFormat: JPG
          cropFocus: CENTER
          maxHeight: 690
          maxWidth: 844
          jpegQuality: 40
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    introText: file(
      name: { eq: "introduction" }
      sourceInstanceName: { eq: "page-content" }
      extension: { eq: "md" }
    ) {
      id
      name
      childMarkdownRemark {
        rawMarkdownBody
        id
        html
        frontmatter {
          title
          name
          button
          buttonText
          services {
            title
            icon
            text
          }
          category
          subtitle
          description
          date
          subline
          button
          url
        }
      }
    }
  }
`

const Introduction = () => {
  return (
    <StaticQuery
      query={introductionQuery}
      render={({ introText, introImage }) => (
        <section data-scrollax-parent="true" id="sec2">
          <div
            className="section-subtitle"
            data-scrollax="properties: { translateY: '-250px' }"
          >
            <span>/</span>Words About
          </div>
          <Container id="container">
            <Row>
              <Col lg="5" md="12">
                <div className="collage-image fl-wrap">
                  <Parallax y={[-100, 100]} className="parallaxWrapper">
                    <div className="collage-image-title">Jezek Jan.</div>
                  </Parallax>
                  <Img
                    fluid={introImage.childImageSharp.fluid}
                    alt={introText.childMarkdownRemark.frontmatter.title}
                  />
                </div>
              </Col>
              <Col lg="7" md="12">
                <div className="main-about fl-wrap">
                  <h5>
                    Um große Dinge zu tun, braucht es nicht immer ein großes
                    Team. Es braucht einen kreativen Geist
                  </h5>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: introText.childMarkdownRemark.frontmatter.title,
                    }}
                  ></h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: introText.childMarkdownRemark.html,
                    }}
                  ></p>
                  <div className="features-box-container fl-wrap">
                    <Row>
                      {introText.childMarkdownRemark.frontmatter.services.map(
                        ({ icon, title, text }, index) => {
                          return (
                            <Col key={index} className="features-box" md="6">
                              <div className="time-line-icon">
                                <i className={`fal ${icon}`}></i>
                              </div>
                              <h3>{title}</h3>
                              <p>{text}</p>
                            </Col>
                          )
                        }
                      )}
                    </Row>
                  </div>
                  <Link
                    cover
                    direction="down"
                    duration={2}
                    bg="#2c2d32"
                    to={introText.childMarkdownRemark.frontmatter.button}
                    className="btn float-btn flat-btn color-btn"
                  >
                    {introText.childMarkdownRemark.frontmatter.buttonText}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    ></StaticQuery>
  )
}

export default Introduction
