import React, { useState } from "react"
import "./horizonta-bar.css"

function HorizontalBar({ width, title }) {
  return (
    <div>
      <div className="custom-skillbar-title">
        <span>{title}</span>
      </div>
      <div className="skill-bar-percent">{width}%</div>
      <div className="skillbar-bg">
        <div
          className="custom-skillbar"
          style={{
            width: `${width}%`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default HorizontalBar
