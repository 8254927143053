import React, { Component } from "react"
import { Parallax } from "react-scroll-parallax"
import "./work-process.css"
import { Link } from "gatsby"
import SecLines from "../sec-lines/sec-lines"

class WorkProcess extends Component {
  render() {
    return (
      <section>
        <Parallax
          y={[-50, 50]}
          className="section-subtitle"
          styleInner={{ color: "black", fontSize: "125px" }}
        >
          Arbeitsweise
        </Parallax>
        <div className="container">
          <div className="section-title fl-wrap">
            <h3>ARBEITSPROZESS</h3>
            <h2>
              Wie ich <span>Arbeite</span>
            </h2>
            <p>
              Als Ein-Mann-Agentur müssen viele Dinge auf das Wesentliche
              ausgerichtet, aber immer losgelöst im kreativen Fluss stattfinden.
            </p>
          </div>
          <div className="process-wrap fl-wrap">
            <ul>
              <li>
                <div className="time-line-icon">
                  <i className="fal fa-chalkboard-teacher"></i>
                </div>
                <h4>Das Projekt besprechen</h4>
                <div className="process-details">
                  <h6>Ich brauche Informationen!</h6>
                  <p>
                    Sie erzählen mir alles über ihr Vorhaben, Ihre Wünsche und
                    Ideen, Erwartungen, Bedenken und das Budget. Basierend auf
                    diesen Daten erarbeite ich Lösungsvorschläge und wir
                    entscheiden gemeinsam über das weitere Vorgehen
                  </p>
                  <Link to="/services">Weitere Details</Link>
                </div>
                <span className="process-numder">01.</span>
              </li>
              <li>
                <div className="time-line-icon">
                  <i className="fal fa-laptop"></i>
                </div>
                <h4>Entwicklung & Ausarbeitung</h4>
                <div className="process-details">
                  <h6>Dies funktioniert von ganz allein</h6>
                  <p>
                    Ich ziehe es vor, unabhängig zu arbeiten. Feedback in
                    bestimmten Entwicklungsstadien ist dabei selbstverständlich.
                    Änderungen sind möglich, können aber bei guter Datenbasis
                    und einer transparenten Vorgehensweise reduziert werden,
                    dies spart drastisch Kosten.
                  </p>
                  <Link to="/services">Weitere Details</Link>
                </div>
                <span className="process-numder">02.</span>
              </li>
              <li>
                <div className="time-line-icon">
                  <i className="fal fa-flag-checkered"></i>
                </div>
                <h4>Abschließende Abnahme</h4>
                <div className="process-details">
                  <h6>Von der Zufriedenheit zur Begeisterung</h6>
                  <p>
                    Ihre Mitarbeiter werden eingewiesen, Dokumente werden
                    übergeben. Nach Fertigstellung und eventuellen Änderungen
                    wird die zweite Teilzahlungsrate fällig. Danach gilt das
                    Projekt als abgeschlossen. Und ich hoffe, einen weiteren
                    Kunden glücklich gemacht zu haben.
                  </p>
                  <Link to="/services">Weitere Details</Link>
                </div>
                <span className="process-numder">03.</span>
              </li>
            </ul>
          </div>
          <div className="fl-wrap mar-top">
            <div className="srv-link-text">
              <h4>
                Benötigen Sie mehr Informationen? Besuchen Sie meine
                Dienstleistungsseite:
              </h4>
              <Link to="/services" className="btn float-btn flat-btn color-btn">
                Meine Leistungen
              </Link>
            </div>
          </div>
        </div>

        <SecLines />
      </section>
    )
  }
}

export default WorkProcess
