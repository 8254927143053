import React, { createRef, Component } from "react"
import ReactCountTo from "../react-count-to/react-count-to"
import { InView } from "react-intersection-observer"
import "./facts.css"
import { graphql, StaticQuery } from "gatsby"

const query = graphql`
  {
    image: file(name: { eq: "binary-code" }) {
      id
      childImageSharp {
        fluid(
          maxWidth: 350
          jpegQuality: 74
          toFormat: JPG
          fit: COVER
          cropFocus: WEST
          maxHeight: 350
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const days_between = date1 => {
  const ONE_DAY = 1000 * 60 * 60 * 24
  const differenceMs = Math.abs(date1 - new Date())
  return Math.round(differenceMs / ONE_DAY)
}
const worksHoursPerDay = 9
const drinksCoffeePerday = 3
const customersPerYear = 5
const projectsPerYear = 7
const iStartedAt = new Date("2013, 01, 01")
const coffeesDrunk = Math.floor(days_between(iStartedAt) * drinksCoffeePerday)
const hoursWorked = Math.floor(days_between(iStartedAt) * worksHoursPerDay)
const happyCustomers =
  (Math.floor(days_between(iStartedAt)) / 365) * customersPerYear
const projectsFinished =
  (Math.floor(days_between(iStartedAt)) / 365) * projectsPerYear

class Facts extends Component {
  state = {
    title:
      "Um <span>Großartige Dinge</span> zu tun, braucht es nicht immer ein grosses Team",
    content:
      "Ich war schon immer an vielen Disziplinen insteressiert, wollte mich nicht festlegen, habe mich ständig fortgebildet. Jetzt bin ich Ihr <span>interdisziplinäre Partner.</span>",
    image: "images/home/binary-code.jpg",
    counterItems: [
      {
        title: "Abgeschlossene Projekte",
        start: 0,
        end: projectsFinished,
        duration: 3500,
      },
      { title: "Arbeitstunden", start: 0, end: hoursWorked, duration: 5000 },
      {
        title: "Glückliche Kunden",
        start: 0,
        end: happyCustomers,
        duration: 3000,
      },
      { title: "Kaffees", start: 0, end: coffeesDrunk, duration: 6000 },
    ],
    countInView: false,
  }

  constructor(props) {
    super(props)
    this.myRef1 = createRef()
    this.myRef2 = createRef()
    this.myRef3 = createRef()
    this.myRef4 = createRef()
  }

  start(counter) {
    this[`myRef${counter}`].start()
  }

  onInViewChange = inview => {
    this.setState({ countInView: !inview })
    this.state.counterItems.map((item, idx) => {
      this[`myRef${String(idx + 1)}`].start()
    })
  }

  render() {
    return (
      <StaticQuery
        query={query}
        render={({ image }) => {
          return (
            <section
              className="parallax-section dark-bg sec-half parallax-sec-half-right"
              data-scrollax-parent="true"
            >
              <div className="overlay">
                <img
                  style={{ height: "100%" }}
                  src={image.childImageSharp.fluid.src}
                  alt="Facts"
                />
              </div>
              <div className="container">
                <div className="section-title">
                  <h2
                    dangerouslySetInnerHTML={{ __html: this.state.title }}
                  ></h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: this.state.content }}
                  ></p>

                  <div className="horizonral-subtitle">
                    <span>Fakten</span>
                  </div>
                </div>
                <InView
                  as="div"
                  onChange={(inView, entry) => this.onInViewChange(inView)}
                >
                  <div className="fl-wrap facts-holder">
                    {this.state.counterItems.map((item, idx) => {
                      return (
                        <div key={idx + 1} className="inline-facts-wrap">
                          <div className="inline-facts">
                            <div className="milestone-counter">
                              <div className="stats animaper">
                                <ReactCountTo
                                  className="num"
                                  ref={el =>
                                    (this[`myRef${String(idx + 1)}`] = el)
                                  }
                                  startVal={item.start}
                                  endVal={item.end}
                                  duration={item.duration}
                                />
                              </div>
                            </div>
                            <h6>{item.title}</h6>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </InView>
              </div>
            </section>
          )
        }}
      ></StaticQuery>
    )
  }
}

export default Facts
