import React, { useContext } from "react"
import classNames from "classnames"
import { ShareContext } from "../../context/ShareContext"
import "./share-content.scss"
const ShareContent = () => {
  const { collapsedShare, toggleShare } = useContext(ShareContext)
  return (
    <>
      <div
        className={classNames("share-wrapper", {
          isShare: !collapsedShare,
          "invis-share": collapsedShare,
        })}
        styles={collapsedShare ? "display: block;" : "display: none;"}
      >
        <div className="share-title">
          <span>Teilen</span>
        </div>
        <div
          className={classNames("close-share soa", { soavis: collapsedShare })}
        >
          <span>Schliessen</span>
          <i className="fal fa-times"></i>
        </div>
        <div
          className={classNames("share-inner soa", { soavis: collapsedShare })}
        >
          <div className="share-container">
            <a
              href="http://www.linkedin.com/in/jezekjan"
              className="pop share-icon share-icon-linkedin"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://www.facebook.com/jezekjancom"
              className="pop share-icon share-icon-facebook"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://www.instagram.com/jezekjancom"
              className="pop share-icon share-icon-instagram"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://twitter.com/jezekjancom"
              className="pop share-icon share-icon-twitter"
              rel="noopener noreferrer"
            ></a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShareContent
