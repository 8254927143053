import React from "react"
import HorizontalBar from "../charts/horizontal-bar"
import { Row } from "reactstrap"

const DeveloperSkills = ({ title, description, types, index }) => {
  return (
    <div className="fl-wrap mar-bottom mar-top skill-wrap">
      <Row>
        <div className="col-md-2"></div>
        <div className="col-md-4">
          <div className="pr-title fl-wrap">
            <h3>{title}</h3>
            <span>{description}</span>
          </div>
          <div className="ci-num">
            <span>{index} </span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="skillbar-box animaper">
            {types.map(({ type, value }, idx) => {
              return <HorizontalBar key={idx} width={value} title={type} />
            })}
          </div>
        </div>
      </Row>
    </div>
  )
}

export default DeveloperSkills
