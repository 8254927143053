import React, { Component } from "react"
import CustomerQuotes from "../customer-quotes/consumer-quotes"
import { Parallax } from "react-scroll-parallax"
import SimpleSlider from "react-slick"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./testimonials.css"
import SecLines from "../../components/sec-lines/sec-lines"

const query = graphql`
  {
    quotes: allFile(filter: { sourceInstanceName: { eq: "quotes" } }) {
      edges {
        node {
          id
          relativePath
          childMarkdownRemark {
            html
            frontmatter {
              name
              jobTitle
              stars
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    clientLogos: allFile(
      filter: { sourceInstanceName: { eq: "client-logos" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fixed(width: 156, jpegQuality: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
class Testimonials extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
    }
  }
  render() {
    const sliderGallerySettings = {
      className: "text-carousel cur_carousel-slider-container fl-wrap",
      infinite: true,
      autoplaySpeed: 2000,
      rows: 2,
      speed: 1950,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      draggable: true,
      dots: false,
      arrows: false,
      centerMode: true,
      initialSlide: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            centerMode: true,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 3,
            centerMode: true,
          },
        },
        {
          breakpoint: 570,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return (
      <StaticQuery
        query={query}
        render={data => {
          return (
            <section id="sec6">
              <Parallax
                y={[-50, 50]}
                className="section-subtitle"
                styleInner={{ color: "black", fontSize: "125px" }}
              >
                Wie ich arbeite
              </Parallax>

              <div className="container">
                <div className="section-title fl-wrap">
                  <h3>Rezensionen</h3>
                  <h2>
                    <span>Testimonials</span> meiner Kunden
                  </h2>
                  <p>
                    Meine wichtigste Aufgabe ist es, meine Kunden glücklich zu
                    machen. Hier sind einige Worte von ihnen, nachdem ich die
                    Auftäge beendet habe.
                  </p>
                </div>
              </div>
              <div className="clearfix"></div>
              <CustomerQuotes quotes={data.quotes.edges} />

              <div className="fl-wrap">
                <div className="container">
                  <ul className="client-list client-list-white">
                    <SimpleSlider
                      ref={slider => (this.slider = slider)}
                      {...sliderGallerySettings}
                    >
                      {data.clientLogos.edges.map((logo, idx) => {
                        return (
                          <li key={idx}>
                            <Img
                              fixed={logo.node.childImageSharp.fixed}
                              alt="Logo"
                            />
                          </li>
                        )
                      })}
                    </SimpleSlider>
                  </ul>
                </div>
              </div>
              <SecLines />
            </section>
          )
        }}
      ></StaticQuery>
    )
  }
}

export default Testimonials
