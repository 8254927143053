import React from "react"
import Img from "gatsby-image"
const Heroslide = ({ node }) => (
  <div className="half-slider-img-item">
    <Img
      alt="Slider Image"
      className="heroImage"
      fluid={node.childImageSharp.fluid}
    />
    <div className="overlay"></div>
  </div>
)

export default Heroslide
