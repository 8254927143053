import React, { Component } from "react"
import SimpleSlider from "react-slick"
import { Container } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons"
import ConsumerQuotesItem from "./consumer-quotes-item"

import "./consumer-quotes.css"
class CustomerQuotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }
  render() {
    var sliderGallerySettings = {
      className: "text-carousel cur_carousel-slider-container fl-wrap",
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      slide: "row",
      arrows: false,
      centerPadding: "0",
      centerMode: true,
      initialSlide: 2,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 2,
          },
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    }
    return (
      <div className="slider-carousel-wrap text-carousel-wrap fl-wrap">
        <div className="text-carousel-controls fl-wrap">
          <Container>
            <div className="sp-cont sp-cont-prev" onClick={this.previous}>
              <FontAwesomeIcon icon={faLongArrowAltLeft} />
            </div>
            <div className="sp-cont sp-cont-next" onClick={this.next}>
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </div>
          </Container>
        </div>
        <SimpleSlider
          ref={slider => (this.slider = slider)}
          {...sliderGallerySettings}
        >
          {this.props.quotes.map((quote, idx) => {
            return <ConsumerQuotesItem key={idx} quote={quote} />
          })}
        </SimpleSlider>
      </div>
    )
  }
}

export default CustomerQuotes
