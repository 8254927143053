import React, { Component, useState } from "react"
import SimpleSlider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"

import "./hero.scss"
import HeroSlide from "./HeroSlide"
import HeroText from "./HeroText"
import Dots from "../animated-background/animated-background-dot"
import { StaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    slides: allFile(
      filter: { sourceInstanceName: { eq: "slides" } }
      sort: { fields: name }
    ) {
      totalCount
      edges {
        node {
          extension
          name
          childMarkdownRemark {
            frontmatter {
              title
              subline
              button
              url
            }
            id
          }
          childImageSharp {
            fluid(
              toFormat: JPG
              cropFocus: CENTER
              maxHeight: 690
              maxWidth: 844
              jpegQuality: 40
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

class Slider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
      nav1: null,
      nav2: null,
    }

    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  next() {
    this.slider1.slickNext()
  }

  previous() {
    this.slider1.slickPrev()
  }

  stringToHTML = function (str) {
    var parser = new DOMParser()
    var doc = parser.parseFromString(str, "text/html")
    return doc.body
  }

  render() {
    var settingsImageSlider = {
      className: "half-slider-img",
      asNavFor: this.state.nav2,
      arrows: false,
      speed: 1000,
      autoplaySpeed: 5500,
      autoplay: true,
      vertical: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      draggable: true,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
    }

    var settingsTextSlider = {
      className: "slider-nav cur_carousel-slider-container",
      asNavFor: this.state.nav1,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      vertical: false,
      fade: true,
      dots: true,
      useCSS: false,

      pauseOnHover: false,
    }

    return (
      <StaticQuery
        query={query}
        render={data => {
          var slideText = data.slides.edges.filter(slide => {
            return slide.node.extension === "md"
          })

          var slideImages = data.slides.edges.filter(slide => {
            return slide.node.extension !== "md"
          })
          return (
            <div className="hero-wrap" id="sec1" data-scrollax-parent="true">
              <div className="hero-inner fl-wrap full-height">
                <div className="half-slider-img-wrap">
                  <SimpleSlider
                    ref={slider => (this.slider1 = slider)}
                    {...settingsImageSlider}
                  >
                    {slideImages.map(({ node }, idx) => {
                      return <HeroSlide key={idx + 1} node={node} />
                    })}
                  </SimpleSlider>
                </div>
                <div className="half-slider-wrap slider-carousel-wrap fl-wrap full-height">
                  <SimpleSlider
                    ref={slider => (this.slider2 = slider)}
                    {...settingsTextSlider}
                  >
                    {slideText.map(({ node }, idx) => {
                      return (
                        <HeroText
                          key={idx + 1}
                          {...node.childMarkdownRemark.frontmatter}
                        />
                      )
                    })}
                  </SimpleSlider>

                  <div
                    className="sp-cont sarr-contr sp-cont-prev"
                    onClick={this.previous}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </div>
                  <div
                    className="sp-cont sarr-contr sp-cont-next"
                    onClick={this.next}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                  <div className="slider-nav-counter">
                    {this.state.activeSlide + 1} / {data.slides.totalCount / 2}
                  </div>
                </div>

                <div className="half-bg-dec">
                  <Dots />
                </div>
                <div className="hero-decor-numb">
                  <span>48.20849 </span>
                  <span>16.37208 </span>
                  <a
                    href="https://goo.gl/maps/QJvW7KQCUUkeJTar5"
                    target="_blank"
                    className="hero-decor-numb-tooltip"
                    rel="noopener noreferrer"
                  >
                    Based In Vienna / Austria
                  </a>
                </div>
                <div className="pattern-bg"></div>
                <div className="hero-line-dec"></div>
              </div>
            </div>
          )
        }}
      ></StaticQuery>
    )
  }
}

export default Slider
